$orange-700: #FF6633;
$orange-900: #CE4C21;

$teal-300: #E0F0F0;
$teal-500: #A6D0D1;

$blue-200: #99CCCC;
$blue-700: #006699;
$blue-900: #00486D;

$gray-36: #C6C6C6;
$gray-99: #999999;
$gray-500: #666;
$gray-600: #545454;
$gray-700: #333;

$blue-gray-500: #A1ACB7;

$primary-color: $orange-700;
$secondary-color: $blue-700;
$header-color: $blue-700;

$tertiary-200: rgba($blue-200, 0.2);
$tertiary-300: rgba($blue-200, 0.3);

$values-yellow: #FFD611;
$values-green: #669933;
$values-pink: #FF9999;
$values-blue: #99CCCC;
