.for-businesses {
  text-align: center;
  padding: 20px 0 40px 0;

  h2 {
    padding-top: 32px;
    padding-bottom: 16px;
  }

  p {
    text-align: left;
    font-size: 16px;
  }

  .action-button {
    margin-top: 4px;
    margin-bottom: 16px;
  }
}