@import 'styles/sizes.scss';

.layout.ant-layout {
  min-height: 100vh;
}

.container,
.container.ant-menu {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.content {
  background-color: white;
  margin-top: $header-height;
  min-height: calc(100vh - #{$header-height} - #{$footer-height}); // window height - header height - footer height :/
}
