$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-xl-min: 1200px;

// Very Small Devices
@mixin small-mobile {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small devices
@mixin mobile {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Medium - Large devices
@mixin desktop {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
